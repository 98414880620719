import * as React from "react";
import { useIconState } from "./useIconState";
const wrapIcon = (Icon, displayName, options) => {
  const WrappedIcon = React.forwardRef((props, ref) => {
    const state = {
      ...useIconState(props, {
        flipInRtl: options === null || options === void 0 ? void 0 : options.flipInRtl
      }),
      ref
    };
    return React.createElement(Icon, Object.assign({}, state));
  });
  WrappedIcon.displayName = displayName;
  return WrappedIcon;
};
export default wrapIcon;